<template>
  <div class="outfit-calendar pb-5">
    <ocalendar :attributes="calendarAttributes" :selecting-day-id="selectingDay" @dayClicked="calendarDayClicked"></ocalendar>

    <ul class="outfit-list">
      <li v-for="(item) in shownOutfits" :key="item.serial" @click.prevent="viewOutfit(item.serial)">
        <span class="list-tag">日記</span>
        <span class="list-situation">{{item.name}}</span>
        <img :src="require('@/assets/icons/dotdotdot.svg')" class="other-btn" @click.stop="otherClicked(item.serial)" />
      </li>
      <li class="add-li" @click.prevent="createClicked">
        <img class="" :src="require('@/assets/nav_icons/add.png')" />
      </li>
    </ul>

    <transition name="fade">
      <ViewOutfit v-if="showViewOutfit" :id="focusOutfitSerial" @edit="openCreateModal" @deleted="outfitDeleted" @back="closeViewOutfitModal" can-edit can-delete></ViewOutfit>
    </transition>

    <transition name="fade">
      <CreateOutfit v-if="showCreateOutfit" :id="focusOutfitSerial" @back="closeCreateOutfitModal" @updated="outfitUpdated" :create-default-date="selectingDay" @created="outfitCreated"></CreateOutfit>
    </transition>

    <transition name="fade">
      <WcActionModal v-if="showActionModal" :actions="actions" @cancel="closeActionModal" @action="actionClicked"></WcActionModal>
    </transition>

    <transition name="fade">
      <WcCommonModal title="確定刪除" v-if="isConfirmModalShow" @cancel="closeConfirmModal" @ok="deleteConfirm" hide-header>
        <div class="text-center">
          確定刪除?
        </div>
      </WcCommonModal>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapMutations } from 'vuex';
import ViewOutfit from '@/views/closet/ViewOutfit.vue';
import CreateOutfit from '@/views/closet/CreateOutfit.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import WcActionModal from '@/components/commons/WcActionModal.vue';
import ocalendar from '@/components/outfit/OutfitCalendar.vue';
import moment from 'moment';

export default {
  name: 'OutfitCalendar',
  data() {
    return {
      readingBusyName: 'READINGOUTFITLIST',
      deleteBusyName: 'DELETEOUTFITLIST',
      outfits: [],
      focusOutfitSerial: -1,
      selectingDay: '',

      showActionModal: false,
      actions: [
        {
          text: '編輯',
          value: 'edit',
        },
        {
          text: '刪除',
          value: 'delete',
        }
      ],

      showViewOutfit: false,
      showCreateOutfit: false,
      isConfirmModalShow: false,
    };
  },
  created() {
    const today = moment().format('YYYY-MM-DD');
    this.selectingDay = today;
    this.SETCALENDARDATE(today);
  },
  components: {
    ViewOutfit,
    CreateOutfit,
    WcCommonModal,
    ocalendar,
    WcActionModal,
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
    this.clearComponentBusy(this.deleteBusyName);

    const today = moment().format('YYYY-MM-DD');
    this.SETCALENDARDATE(today);
  },
  computed: {
    ...mapState(['cloths']),
    calendarAttributes() {
      const atrs = [];
      for (const o of this.outfits) {
        const m = moment(o.date);
        atrs.push({
          key: o.serial,
          customData: o,
          dates: m.toDate(),
        });
      }
      return atrs;
    },
    shownOutfits() {
      const outfits = this.outfits.filter((o) => {
        return o.date === this.selectingDay;
      });
      const res = outfits.map((o) => {
        return {
          serial: o.serial,
          name: o.situation.length > 0? o.situation: '[無設定場合]',
        };
      });
      return res;
    },
	},
  watch: {
  },
  async mounted() {
    await this.__refresh();
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    ...mapMutations(['SETCALENDARDATE']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const list = await this.$store.dispatch('api/readOutfitListPromise');
        this.outfits.splice(0, this.outfits.length);
        for (const outfit of list) {
          this.outfits.push(outfit);
        }
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    openCreateModal() {
      this.showCreateOutfit = true;
    },
    closeViewOutfitModal() {
      this.showViewOutfit = false;
    },
    viewOutfit(serial) {
      this.focusOutfitSerial = serial;
      this.showViewOutfit = true;
    },
    closeCreateOutfitModal() {
      this.showCreateOutfit = false;
    },
    closeActionModal() {
      this.showActionModal = false;
    },
    actionClicked(action) {
      switch(action) {
        case 'delete':
          this.showConfirmModal();
          break;
        case 'edit':
          this.openCreateModal();
          break
      }
      this.closeActionModal();
    },
    createClicked() {
      this.focusOutfitSerial = -1;
      this.openCreateModal();
      window.trackBtnClicked('outfitListAddBtn');
    },
    outfitUpdated() {
      this.closeViewOutfitModal();
      this.closeCreateOutfitModal();
      this.__refresh();
    },
    outfitCreated() {
      this.closeCreateOutfitModal();
      this.__refresh();
    },
    outfitDeleted() {
      this.closeViewOutfitModal();
      this.__refresh();
    },
    showConfirmModal() {
      this.isConfirmModalShow = true;
    },
    closeConfirmModal() {
      this.isConfirmModalShow = false;
    },
    async deleteConfirm() {
      this.closeConfirmModal();
      this.appendComponentBusy(this.deleteBusyName);
      try {
        await this.$store.dispatch('api/deleteOutfitPromise', this.focusOutfitSerial);
        await this.__refresh();
      } catch(err) {
        this.appendErrorMsg(err.toString());
      } finally {
        this.clearComponentBusy(this.deleteBusyName);
      }
    },
    calendarDayClicked(day) {
      this.selectingDay = day.id;
      this.SETCALENDARDATE(day.id);

    },
    otherClicked(serial) {
      this.focusOutfitSerial = serial;
      this.showActionModal = true;
    },
  }
}
</script>

<style scoped>
  .outfit-calendar {

  }

  .outfit-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .outfit-list>li {
    position: relative;
    list-style: none;
    padding: .75rem 2rem .75rem 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #eeeeee;
  }

  .outfit-list>li:first-child {
    border-top: solid 1px #eeeeee;
  }

  .outfit-list>li.add-li {
    justify-content: center;
    padding: .75rem 1rem .75rem 1rem;
  }

  .outfit-list>li.add-li>img {
    width: 33px;
  }

  .list-tag {
    background-color: #C4C4C4;
    border-radius: 16px;
    padding: 4px 1.5em;
    color: #4D4D4D;
    font-size: 12px;
  }

  .list-situation {
    border: 1px solid #4D4D4D;
    border-radius: 16px;
    padding: 4px 1.5em;
    color: #4D4D4D;
    font-size: 12px;
  }

  .other-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
</style>
