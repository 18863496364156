<template>
  <div class="">
    <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="oc-day" @click="clickDay(day)" :class="{'selected': selectingDayId === day.id, 'hasCloth': attributes&&attributes.length>0}">
          <span>{{ day.day }}</span>
          <div class="has-cloth" v-if="attributes&&attributes.length>0"><img :src="require('@/assets/icons/hasCloth.svg')"/></div>
        </div>
        <!-- <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              {{ attr.customData.title }}
            </p>
          </div>
        </div> -->
      </template>
    </v-calendar>
  </div>
</template>

<script>
export default {
  data() {
    // const month = new Date().getMonth();
    // const year = new Date().getFullYear();
    return {
      masks: {
        weekdays: 'WWW',
      },
      // attributes: [
        // {
        //   key: 1,
        //   customData: {
        //     title: 'Lunch with mom.',
        //     class: 'bg-danger text-white',
        //   },
        //   dates: new Date(year, month, 1),
        // },
        // {
        //   key: 2,
        //   customData: {
        //     title: 'Take Noah to basketball practice',
        //     class: 'bg-blue-500 text-white',
        //   },
        //   dates: new Date(year, month, 2),
        // },
        // {
        //   key: 3,
        //   customData: {
        //     title: "Noah's basketball game.",
        //     class: 'bg-blue-500 text-white',
        //   },
        //   dates: new Date(year, month, 5),
        // },
        // {
        //   key: 4,
        //   customData: {
        //     title: 'Take car to the shop',
        //     class: 'bg-indigo-500 text-white',
        //   },
        //   dates: new Date(year, month, 5),
        // },
        // {
        //   key: 4,
        //   customData: {
        //     title: 'Meeting with new client.',
        //     class: 'bg-teal-500 text-white',
        //   },
        //   dates: new Date(year, month, 7),
        // },
        // {
        //   key: 5,
        //   customData: {
        //     title: "Mia's gymnastics practice.",
        //     class: 'bg-pink-500 text-white',
        //   },
        //   dates: new Date(year, month, 11),
        // },
        // {
        //   key: 6,
        //   customData: {
        //     title: 'Cookout with friends.',
        //     class: 'bg-orange-500 text-white',
        //   },
        //   dates: { months: 5, ordinalWeekdays: { 2: 1 } },
        // },
        // {
        //   key: 7,
        //   customData: {
        //     title: "Mia's gymnastics recital.",
        //     class: 'bg-pink-500 text-white',
        //   },
        //   dates: new Date(year, month, 22),
        // },
        // {
        //   key: 8,
        //   customData: {
        //     title: 'Visit great grandma.',
        //     class: 'bg-red-600 text-white',
        //   },
        //   dates: new Date(year, month, 25),
        // },
      // ],
    };
  },
  props: {
    attributes: {
      required: false,
      type: Array,
      default() {
        return [];
      },
    },
    selectingDayId: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    clickDay(day) {
      this.$emit('dayClicked', day);
    }
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

/deep/ .vc-container {
  border: none;
}

/deep/ .vc-header {
  background-color: #EEEEEE;
  /* padding: 21px 16px 18px 16px; */
  padding: 10px 18px 10px 18px;
}

.oc-day {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  min-height: 60px;
}

.oc-day:not(.hasCloth) {
  opacity: .5;
}

.oc-day.selected {
  background-color: #eee;
}

.has-cloth {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
}

/deep/ .vc-weeks .vc-weekday {
  color: #4D4D4D;
}

/deep/ .vc-weeks .vc-weekday:nth-child(1), /deep/ .vc-weeks .vc-weekday:nth-child(7) {
  color: #7DC4B7;
}

/deep/ .vc-day.weekday-1, /deep/ .vc-day.weekday-7 {
  color: #7DC4B7;
}

/deep/ .vc-day.in-next-month {
  display: none;
}
/*
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}

.vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.vc-weeks {
  padding: 0;
}
.vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
.vc-day.weekday-1, .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}

.vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
.vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.vc-day-dots {
  margin-bottom: 5px;
} */
</style>
